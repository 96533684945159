<template>
  <v-main>
      <div
          class="cookie-notification"
          v-if="cookieShow"
      >
          <p>
              Этот сайт использует cookie и другие технологии для сбора информации технического характера. Подробнее о
              <router-link :to="{name: 'Cookies'}" target="_blank">
                  файлах cookie
              </router-link>
          </p>
          <v-btn
              color="gray"
              class="cookie-accept"
              @click="cookieAccept"
          >
              Хорошо
          </v-btn>
      </div>
      <div class="wrapper">
        <section class="section-1">
          <img src="/img/robomoney/bg-main-top.png" class="bg-main-top" alt="">
          <h1 class="h1-main"><span class="rm-big">R</span>obo<span class="rm-big">M</span>oney</h1>
          <h2 class="h2-main">Робот по автоматизации заработка онлайн <br> на базе самого развитого искусственного интеллекта</h2>
          <h3 class="h3-main">Получи готовую систему под ключ <span class="span-main"> бесплатно</span></h3>
          <v-row class="main-btm-txt">
            <v-col cols="10" md="8" class="txt-main">
              <div>
                <h4 class="h4-main mb-sm-10 mb-5">Используй свое время, проведенное <br> в соц сетях, с пользой!</h4>
                <h4 class="h4-main">Увеличь доход в несколько раз, <br> пока нейросети работают за тебя</h4>
              </div>
            </v-col>
            <v-col cols="2" md="4"><img src="/img/robomoney/bg-main-btm.png" class="bg-main-btm" alt=""></v-col>
          </v-row>
        </section>

        <section class="section-2">
          <h2 class="h2-title-sw">Если тебе актуально:</h2>
          <v-row class="sec-2-row-top">
            <v-col cols="12" sm="6">
              <ul class="rm-ul-col">
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Рост дохода в 2-3 раза уже в ближайшие месяцы</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Больше времени проводить с семьей</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Свободно распоряжаться своим временем, рбаотая удаленно</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Создать свое дело онлайн без рисков и не имея опыта</li>
              </ul>
            </v-col>
            <v-col cols="12" sm="6">
              <ul class="rm-ul-col">
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Иметь четкую пошаговую систему стабильного заработка</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Иметь неограниченные перспективы по масштабированию в готовой нише</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Работать в трендовом направлении</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Успешное, развивающееся окружение</li>
              </ul>
            </v-col>
          </v-row>
          <v-row class="main-btm-txt">
            <v-col cols="2" class="txt-main">
              <img src="/img/robomoney/robot-setevik.png" class="robot-setevik" alt="">              
            </v-col>
            <v-col cols="10">
              <div class="sec-2-col-div">
                <h2 class="h2-title"><span class="h2-span">R</span>obo<span class="h2-span">M</span>oney</h2>
                <h3 class="h3-title">твой личный помощник в достижении <br class="sec-2-br-mob"> финансового <br class="sec-2-br"> благосостояния в мире <br class="sec-2-br-mob"> больших возможностей</h3>
              </div>
            </v-col>
          </v-row>         
          
          <img src="/img/robomoney/bg-sec-23.png" class="bg-sec-23" alt="">
        </section>

        <section class="section-3">
          <h2 class="h2-title-sw">Начни использовать технологии будущего <br class="br-mob"> для роста твоего дохода уже сегодня</h2>
          <v-row>
            <v-col cols="12" sm="5">
              <div>
                <h2 class="h2-title text-center">Если ты:</h2>
                <ul class="rm-ul-col mx-auto">
                  <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Мама в декрете</li>
                  <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Работаешь в найме</li>
                  <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Предприниматель</li>
                  <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Блогер</li>
                  <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Эксперт</li>
                  <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">В поисках себя</li>
                </ul>
            </div>
            </v-col>
            <v-col cols="12" sm="7" class="pr-sm-5 pr-2">
              <div class="video-block">
                <div style="width: 100%; max-width: 800px; height: auto; margin: 0 auto">
                  <div class="thumb-wrap">
                    <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/jGjI3XNO1s0?si=Lojn5Sz6ZspCHJgP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
                    <iframe src="https://vk.com/video_ext.php?oid=-206686778&id=456239039&hd=1" width="640" height="360" allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>         
          
          <h3 class="h3-title">Можно совмещать с любым видом деятельности<br class="br-mob"> и устанавливать свой график</h3>
        </section>

        <section class="section-6">
          <h2 class="h2-title-sw">На что способен Robomoney:</h2>
          <v-row class="col-sec-6-top">
            <v-col cols="10" sm="9">
              <ul class="rm-ul-col">
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Упаковка профиля в соц сетях под заданную ЦА</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Все виды контента: фото, видео, текст</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Раскрутка и набор подписчиков без бюджета</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Поиск клиентов на автопилоте</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Работа без перерывов и выходных 24/7</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Автоматизация 80% рутинной работы</li>
              </ul>
            </v-col>
            <v-col class="col-img-abs" cols="2" sm="3">
              <img src="/img/robomoney/had-mirror.png" class="robo-had" alt="">
            </v-col>
          </v-row>
        </section>
        
        <section class="section-9">
          <h2 class="h2-title-sw">Гарантия твоего результата - <br class="br-mob-9"> доходы наших партнёров</h2>
          <div>
            <v-carousel
              class="rm-carousel"
              cycle
              hide-delimiter-background
              show-arrows-on-hover
              hide-delimiters
            >
              <v-carousel-item
                  v-for="(slide, i) in slides"
                  :key="i"
              >
                <v-sheet
                  height="100%"
                >
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center"
                  >
                    <div class="text-h2">
                      <p style="width: 100%; max-width: 800px; height: auto">
                          <img class="img-carousel" style="width: 100%;" :src=slide.src alt=""/>
                      </p>
                    </div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </div>
          <div class="text-center">
            <button class="btn-want" @click="scrollToForm()"><img src="/img/robomoney/btn-want.png" class="img-want" alt=""></button>
          </div>
        </section>

        <section class="section-92">
          <h2 class="h2-title-sw">Алгоритм выхода на доход:</h2>
          <v-row class="col-sec-92-top">
            <v-col cols="12" sm="8">
              <ul class="rm-ul-col">
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Оставь заявку</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Получи консультацию</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Пройди обучение</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Подключи robomoney</li>
                <li><img src="/img/robomoney/check-li.png" class="check-li" alt="">Будь уверен в завтрашнем дне</li>
              </ul>
            </v-col>
            <v-col class="col-img-92" cols="12" sm="4">
              <img src="/img/robomoney/notebook-hand.png" class="notebook-hand" alt="">
            </v-col>
          </v-row>
          
          <img src="/img/robomoney/notebook-hand.png" class="notebook-hand-mob" alt="">
        </section>

        <section v-if="userData.id != 127" class="section-10" id="rm-form">
            <v-container class="text-center px-0">
                <h2 class="h2-title-sw">Зарабатывайте больше, работая меньше</h2>
                <h3 class="h3-form">Оставь заявку <br class="h3-form-br-mob"> и получи своего робота помощника</h3>
                <v-responsive
                    class="mx-auto"
                    max-width="600px"
                >
                    <v-text-field
                      class="form-name"
                        label="Ваше имя"
                        prepend-icon="mdi-face-man"
                        type="text"
                        v-model.trim="newProspect.name"
                        :error-messages="nameErrors"
                        @input="$v.newProspect.name.$touch()"
                        @blur="$v.newProspect.name.$touch()"
                    />
                    <v-text-field
                        class="input-phone"
                        label="Телефон"
                        prepend-icon="mdi-phone"
                        type="text"
                        v-model.trim="newProspect.phone"
                        :error-messages="phoneErrors"
                        @input="$v.newProspect.phone.$touch()"
                        @blur="$v.newProspect.phone.$touch()"
                    />
                    <div class="invalid-feedback" v-if="errors.phone">
                        {{ errors.phone[0] }}
                    </div>

                    <p style="text-align: center; margin-bottom: 0;">
                        <btn
                            class="mr-0 btn-send"
                            @click="addProspectMe()"
                        >
                            <img class="img-send" src="/img/robomoney/btn-send.png">
                        </btn>
                    </p>
                    <!-- <p class="privacy-text rm-privacy">
                        Отправляя заявку, вы даете свое
                        <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                        и принимаете
                        <router-link :to="{name: 'Policy'}" target="_blank">Политику конфиденциальности</router-link>.
                    </p> -->
                </v-responsive>
            </v-container>
            
            <div v-if="userData.id != 127">
              <v-container class="text-center">
                <h3 class="h3-form cntct">Напиши в мессенджер <br class="br-form-cntct-mob"> и сразу получи ответ</h3>

                <v-card
                    class="cntct-bg v-card-profile v-card--material mt-16 pa-3 mx-auto"
                    max-width="430"
                >
                    <div class="d-flex grow flex-wrap">
                        <div
                            class="v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                            style="height: 128px; min-width: 128px; width: 128px"
                        >
                            <div class="v-image v-responsive theme--light">
                                <img v-if="userData.avatar"
                                      :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                      alt=""
                                >
                                <div class="v-responsive__content" style="width: 250px"></div>
                            </div>
                        </div>

                        <v-card-text class="text-center cntct-fio">
                            <h4 class="display-2 black--text">
                                {{ userData.name }} {{ userData.last_name }}
                            </h4>
                        </v-card-text>
                    </div>
                    <v-divider style="padding-bottom: 20px"/>
                    <v-row>
                        <v-col class="text-center">
                            <div class="input-group mb-3">
                                <v-tooltip
                                    top
                                    v-if="userData.phone_whatsapp"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="success"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-whatsapp</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в WhatsApp</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.telegram"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`${userData.telegram}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>fab fa-telegram-plane</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Telegram</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
              </v-container>
            </div>
            <h3 class="h3-title">RoboMoney - твой надежный партнер <br class="br-mob"> в мире больших возможностей</h3>
            <img class="bg-end" src="/img/robomoney/bg-end.png">
        </section>
        <!-- <section
            class="rm-footer"
        >
            <div class="title font-weight-light text-center">
                &copy; {{ (new Date()).getFullYear() }} — newlvl.net — независимый партнер LR Health & Beauty
            </div>
        </section> -->

        <v-dialog
            v-model="dialog_prospect"
            width="500"
            class="dialog-prospect"
        >
            <v-card>
                <v-card-title class="headline lighten-2 pb-5" style="background-color: #431857; color: white">
                    Заявка принята
                </v-card-title>
                <v-card-text class="mt-5">
                    Ваш консультант свяжется с вами в ближайшее время
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="#431857"
                        text
                        @click="dialog_prospect = false"
                    >
                        Хорошо
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
  </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";

export default {
  name: "RoboMoneyShort",
  data: () => ({
      newProspect: {
          phone: '',
          partner: '',
          instrument: 'RoboMoney short',
          step: 'Новый'
      },
      slides: [
        {
            src: '/img/robomoney/otzivi/1.png',
        },
        {
            src: '/img/robomoney/otzivi/2.png',
        },
        {
            src: '/img/robomoney/otzivi/3.png',
        },
        {
            src: '/img/robomoney/otzivi/4.png',
        },
        {
            src: '/img/robomoney/otzivi/5.png',
        },
        {
            src: '/img/robomoney/otzivi/6.png',
        },
        {
            src: '/img/robomoney/otzivi/7.png',
        },
        {
            src: '/img/robomoney/otzivi/8.png',
        },
        {
            src: '/img/robomoney/otzivi/9.png',
        },
        {
            src: '/img/robomoney/otzivi/10.png',
        },
        {
            src: '/img/robomoney/otzivi/11.png',
        },
        {
            src: '/img/robomoney/otzivi/12.png',
        },
        {
            src: '/img/robomoney/otzivi/13.png',
        },
      ],
      errors: {},
      dialog_prospect: false,
      loading: false,
      cookieShow: true,
  }),
  metaInfo: {
      title: 'RoboMoney - твой прорыв в сетевом бизнесе',
      meta: [
          { vmid: 'description', property: 'description', content: 'RoboMoney - уникальный робот по полной автоматизации MLM на базе искусственного интеллекта' },
          { vmid: 'og:title', property: 'og:title', content: 'RoboMoney - твой прорыв в сетевом бизнесе' },
          { vmid: 'og:description', property: 'og:description', content: 'RoboMoney - уникальный робот по полной автоматизации MLM на базе искусственного интеллекта' },
      ],
  },
  created() {
      if (!this.$route.query.partner) {
          if (this.$cookies.isKey('newlvl_partner')) {
              const partnerNum = this.$cookies.get('newlvl_partner')
              this.$router.push ({name: 'RoboMoneyShort', query: { partner: partnerNum }})
          }
      } else {
          this.$cookies.set('newlvl_partner', this.$route.query.partner, '6m')
      }

      if (this.$cookies.isKey('newlvl_cookie')) {
          this.cookieShow = false
      }
  },
  computed: {
      ...mapGetters({
          userData: 'user/getUser'
      }),
      dataUser() {
          return this.userData
      },
      nameErrors() {
          const errors = []
          if (!this.$v.newProspect.name.$dirty) return errors
          !this.$v.newProspect.name.minLength && errors.push('Минимум 2 буквы')
          !this.$v.newProspect.name.maxLength && errors.push('Максимум 30 букв')
          !this.$v.newProspect.name.required && errors.push('Обязательно для заполнения')
          return errors
      },
      phoneErrors() {
          const errors = []
          if (!this.$v.newProspect.phone.$dirty) return errors
          !this.$v.newProspect.phone.numeric && errors.push('Только цифры')
          !this.$v.newProspect.phone.minLength && errors.push('Минимум 5 цифр')
          !this.$v.newProspect.phone.maxLength && errors.push('Максимум 30 цифр')
          !this.$v.newProspect.phone.required && errors.push('Обязательно для заполнения')
          return errors
      }
  },
  methods: {
      ...mapActions({
          addNotification: 'application/addNotification',
          showUser: 'user/showUserPage',
          createProspect: 'user/addClient'
      }),
      cookieAccept() {
          this.cookieShow = false
          this.$cookies.set('newlvl_cookie', 'accept', '12m')
      },      
      scrollToForm () {
          let scrollBottom = document.getElementById("rm-form")
          scrollBottom.scrollIntoView({ behavior: "smooth" })
      },
      addProspectMe() {
          this.$v.$touch()
          if (!this.$v.$invalid) {
              this.loading = true
              this.createProspect(this.newProspect)
                  .then((response) => {
                      if (response.data && response.data.success) {
                          this.errors = {}
                          this.loading = false
                          this.dialog_prospect = true
                      }
                  })
                  .catch((error) => {
                      switch (error.response.status) {
                          case 422:
                              this.loading = false
                              this.addNotification({
                                  show: true,
                                  text: error.response.data.message,
                                  color: 'error'
                              })
                              this.errors = error.response.data.errors
                              break
                          case 500:
                              this.loading = false
                              this.addNotification({
                                  show: true,
                                  text: error.response.data.message,
                                  color: 'error'
                              })
                              this.errors = error.response.data.errors
                              break
                          default:
                              this.loading = false
                              this.addNotification({
                                  show: true,
                                  text: error.response.data.message,
                                  color: 'error'
                              })
                              this.errors = error.response.data.errors
                              break
                      }
                  })
          } else {
              this.addNotification({
                  show: true,
                  text: 'Ошибка валидации',
              })
          }
      },
  },
  mounted () {
    this.showUser({user: this.$route.query.partner, inst: this.$route.query.inst})
    this.newProspect.partner = this.$route.query.partner
  },
  validations: {
      newProspect: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30)
        },
        phone: {
          required,
          numeric,
          minLength: minLength(5),
          maxLength: maxLength(30)
        }
      }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Intro";
  src: url('/fonts/IntroDemo-BlackCAPS.otf');
}
@font-face {
  font-family: "Montserrat";
  src: url('/fonts/Montserrat-Regular.ttf');
}
.wrapper {
  font-size: 16px;
  color: #fff;
  font-family: "Intro";
  overflow: hidden;
  // background: linear-gradient(to right, #431857 55%, #0F295E);
  background-image: url(/img/robomoney/bg-e.jpg);
  background-size: cover;
}
.cntct-bg {
  background-color: rgba($color: #fff, $alpha: 0.5) !important;
}
.cntct-fio {
  margin-top: -30px;
}
.cntct-fio h4 {
  font-weight: 600 !important;
}
.br-form-cntct-mob {
  display: none;
}
.section-1 {  
  position: relative;
  text-align: center;
}
.bg-main-top {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(150px + (200 - 20) * ((100vw - 320px) / (1280 - 320)));
  z-index: 100;
  opacity: 0.6;
}
.bg-main-btm {
  width: 100%;
  position: relative;
  bottom: 0;
  right: 0;
}
.h1-main {
  text-align: center;
  font-size: calc(24px + (120 - 24) * ((100vw - 320px) / (1280 - 320)));
  padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-left: 50px;
  letter-spacing: 4px;
  position: relative;
  z-index: 200;
}
.rm-big {
  font-size: calc(30px + (140 - 34) * ((100vw - 320px) / (1280 - 320)));
}
.h2-main {
  text-align: center;
  font-size: calc(10px + (42 - 20) * ((100vw - 320px) / (1280 - 320)));
  letter-spacing: 2px;
  position: relative;
  z-index: 200;
}
.h3-main {
  font-size: calc(10px + (42 - 20) * ((100vw - 320px) / (1280 - 320)));
  line-height: 130%;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 40px;
}
.span-main {
  font-size: calc(12px + (58 - 24) * ((100vw - 320px) / (1280 - 320)));
}
.br-main, .br-main-mob {
  display: none;
}
.main-btm-txt {
  padding-left: calc(10px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.txt-main  {
  display: flex;
  text-align: center;
  // justify-content: center;
  padding-left: 80px;
  align-items: center;
  position: relative;
  z-index: 200;
}
.h4-main {
  font-size: calc(9px + (32 - 14) * ((100vw - 320px) / (1280 - 320)));
  line-height: 130%;
  letter-spacing: 0px;
  text-align: left;
}
.h2-title-sw {
  text-shadow: 1px 1px 8px #ccc;
  text-align: center;
  font-size: calc(16px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  line-height: 130%;
  letter-spacing: 3.5px;
  padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1280 - 320)));
  position: relative;
  z-index: 500;
}
.section-2 {
  position: relative;
  margin-top: -60px;
}
.section-2 .h2-title {
  text-align: center;
  font-size: calc(16px + (60 - 20) * ((100vw - 320px) / (1280 - 320)));
  line-height: 130%;
  letter-spacing: 3.5px;
  padding-top: calc(20px + (50 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  position: relative;
  z-index: 500;
}
.h2-title .h2-span {
  font-size: calc(20px + (80 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.sec-2-br-mob {
  display: none;
}
.section-2 .h3-title {
  font-size: calc(10px + (32 - 18) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: 100px;
  z-index: 300;
  position: relative;
}
.section-3 .rm-ul-col {
  width: 400px;
}
.section-3 .h2-title {
  font-size: calc(16px + (32 - 18) * ((100vw - 320px) / (1280 - 320)));
  margin-bottom: 20px;
}
.section-3 .h3-title {
  font-size: calc(10px + (32 - 18) * ((100vw - 320px) / (1280 - 320)));
}
.rm-ul-col {
  font-family: "Montserrat";
  list-style-type: none;
  font-size: calc(10px + (20 - 12) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  z-index: 200;
}
.rm-ul-col li {
  position: relative;
  padding-left: 40px;
  padding-bottom: 15px;
}
.check-li {
  position: absolute;
  width: 30px;
  left: 0;
}
.sec-2-row-top, .sec-5-row-top {
  padding-left: 40px;
  padding-right: 40px;
}
.robot-setevik  {
  width: 400px;
  position: absolute;
  bottom: -50px;
  left: 0;
  // right: -50px;
}
.txt-col {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.h3-title {
  font-size: calc(14px + (32 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 130%;
  letter-spacing: 1px;
  padding-bottom: 40px;
  text-align: center;
}
.h3-title .h3-span {
  font-size: calc(18px + (36 - 22) * ((100vw - 320px) / (1280 - 320)));
  // text-shadow: 1px 1px 12px #fff;
} 
.h3-title-5 {
  font-size: calc(14px + (28 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 130%;
  letter-spacing: 1px;
  padding-bottom: 40px;
  text-align: center;
}
.h3-title-5 .h3-span {
  font-size: calc(18px + (32 - 22) * ((100vw - 320px) / (1280 - 320)));
  // text-shadow: 1px 1px 12px #fff;
} 
.br-mob-3 {
  display: none;
}
.h3-title-5b {
  font-size: calc(14px + (40 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 130%;
  letter-spacing: 1px;
  padding-bottom: 40px;
  text-align: center;
}
.h3-title-5b .h3-span {
  font-size: calc(18px + (44 - 22) * ((100vw - 320px) / (1280 - 320)));
  // text-shadow: 1px 1px 12px #fff;
} 
.img-rm, .img-want, .img-500, .img-send {
  width: calc(180px + (450 - 200) * ((100vw - 320px) / (1280 - 320)));
}
.bg-sec-23 {
  position: absolute;
  right: 0;
  bottom: -25%;
  width: calc(150px + (200 - 20) * ((100vw - 320px) / (1280 - 320)));
  z-index: 100;
}
.section-3 .h3-title {
  margin-top: 50px;
  font-weight: 400;
  font-family: 'Montserrat';
  text-transform: uppercase;
}
.section-4 {
  position: relative;
}
.section-4 .txt-col {
  justify-content: left;
}
.section-4 .h3-title {
  text-align: left;
  margin-left: calc(10px + (100 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-left: 24px;
  font-size: calc(14px + (40 - 18) * ((100vw - 320px) / (1280 - 320)));
}
.rm-ul {
  font-family: "Montserrat";
  list-style-type: none;
  font-size: calc(11px + (20 - 12) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: calc(10px + (100 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.rm-ul li {
  position: relative;
  padding-left: 40px;
  padding-bottom: 15px;
}
.bg-robo-mozg {
  width: 100%;
}
.robo-mozg-mob {
  display: none;
}
.h2-title-4 {
  text-align: center;
  font-size: calc(24px + (100 - 24) * ((100vw - 320px) / (1280 - 320)));
  padding-left: 50px;
  letter-spacing: 4px;
}
.h2-title-4 span {
  font-size: calc(30px + (120 - 30) * ((100vw - 320px) / (1280 - 320)));
}
.section-5 {
  background-image: url(/img/robomoney/bg-hands.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.txt-sec-5 {
  text-align: center;
  margin-top: 40px;
}
.h2-title-5 {
  text-align: center;
  font-size: calc(24px + (80 - 24) * ((100vw - 320px) / (1280 - 320)));
  padding-left: 50px;
  letter-spacing: 4px;
}
.h2-title-5 span {
  font-size: calc(30px + (100 - 30) * ((100vw - 320px) / (1280 - 320)));
}
.br-mob-5 {
  display: none;
}
.col-img-abs {
  position: relative;
}
.br-mob-6, .br-mob-62 {
  display: none;
}
.h3-form-br-mob {
  display: none;
}
.steps-man {
  position: absolute;
  top: -130px;
  right: -50px;
  width: 850px;
  z-index: 100;
}
.hands-ball {
  width: 130%;
  left: -100px;
  top: -150px;
  position: absolute;
}
.hands-ball-mobile, .steps-man-mob {
  display: none;
}
.hands-col {
  width: 150%;
  left: -120px;
  top: -100px;
  position: absolute;
}
.robo-had {
  width: 230%;
    right: 0px;
    top: -300px;
  position: absolute;
}
.robo-had-mobile {
  display: none;
}
.section-6 .rm-ul-col {
  font-size: calc(10px + (20 - 12) * ((100vw - 320px) / (1280 - 320)));
}
.col-sec-6-top, .col-sec-6-btm {
  padding-left: 40px;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 300;
}
.col-sec-6-top {
  padding-left: 40px;
}
.col-sec-92-top {
  padding-left: 40px;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 300;
}
.h3-title-6 {
  font-size: calc(13px + (36 - 13) * ((100vw - 320px) / (1280 - 320)));
  line-height: 130%;
  letter-spacing: 3px;
  padding-bottom: 40px;
  text-align: center;
  margin-top: 40px;
}
.txt-btm-8 {
  text-align: center;
}
.txt-btm-8 .h3-title {
  margin-top: 40px;
  padding-bottom: 10px;
}
.ul-otzivi {
  font-family: "Montserrat";
  list-style-type: none;
  font-size: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 auto;
  max-width: 1400px;
  padding-top: 20px;
}
.ul-otzivi li {
  position: relative;
  padding-left: 60px;
  padding-bottom: 15px;
}
.br-mob-8 {
  display: none;
}
.section-9 .h3-title {
  margin-top: 40px;
}
.br-mob-9 {
  display: none;
}
.col-img-92 {
  position: relative;
}
.notebook-hand {
  width: 130%;
  position: absolute;
  top: -70px;
  left: -200px;
}
.notebook-hand-mob {
  display: none;
}
.video-block {
  position: relative;
  z-index: 1000;
  max-width: 800px;
  // margin: 0 auto;
  text-align: center;
}
.section-9 .h2-title-sw {
  padding-bottom: 0px;
  letter-spacing: 1px;
}
.rm-carousel {
  position: relative;
  z-index: 500;
  height: 400px !important;
}
.rm-carousel .v-carousel__item {
  height: 400px !important;
}
.rm-carousel .theme--light.v-sheet {
  background-color: rgba($color: #017F8E, $alpha: 0);
}
.br-mob-92 {
  display: none;
}
.form-name .v-icon, .input-phone .v-icon, .form-name .v-label, .input-phone .v-label, .form-name input, .input-phone input {
  color: #fff !important;
}
.form-name .v-input__slot:before, .input-phone .v-input__slot:before {
  border-color: #fff !important;
}
.dialog-prospect {
  z-index: 10000;
}
.rm-footer {
  height: 100px;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  position: relative;
}
.h3-form {
  font-size: calc(12px + (22 - 12) * ((100vw - 320px) / (1280 - 320)));  
  text-transform: uppercase;
  margin-bottom: 40px;
  font-family: "Montserrat";
}
.section-10 .h2-title-sw {
  padding-bottom: 40px;
}
.section-10 .h3-title {
  margin-top: 40px;
  position: relative;
  z-index: 200;
}
.privacy-text.rm-privacy {
  color: #fff !important;
  font-family: "Montserrat";
}
.privacy-text.rm-privacy a {
  color: #00e1ff !important;
}
.btn-send {
  cursor: pointer;
}
.bg-end {
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(150px + (200 - 20) * ((100vw - 320px) / (1280 - 320)));
  z-index: 100;
  opacity: 0.6;
}
.h2-title-mob-form {
  display: none;
}
@media (min-width: 1700px) {
  .h2-main {
    font-size: 42px;
  }
  .h3-main {
    font-size: 42px;
  }
}
@media (min-width: 1300px) {
  .h1-main{
    font-size: 120px;
  }
  .rm-big {
    font-size: 140px;
  }
  // .h2-main {
  //   font-size: 42px;
  // }
  // .h3-main {
  //   font-size: 42px;
  // }
  .span-main {
    font-size: 58px;
  }
  .h4-main {
    font-size: 32px;
  }
  .h2-title-sw {
    font-size: 40px;
  }
  .section-2 .h2-title {
    font-size: 60px;
  }
  .h2-title .h2-span {
    font-size: 80px;
  }
  .h3-title .h3-span {
    font-size: 36px;
  }
  .h3-title, .h3-title-6 {
    font-size: 32px;
  }  
  .h3-title-5 .h3-span {
    font-size: 32px;
  }
  .h3-title-5 {
    font-size: 28px;
  }  
  .h3-title-5b .h3-span {
    font-size: 44px;
  }
  .h3-title-5b {
    font-size: 40px;
  }  
  .section-3 .h2-title-sw, .section-7 .h2-title-sw, .section-8 .h2-title-sw {
    padding-bottom: 50px;
  }
  .section-8 .h2-title-sw {
    padding-top: 100px;
  }
  .section-9 .h2-title-sw {
    padding-top: 60px;
    padding-bottom: 0px;
    font-size: 36px;
    letter-spacing: 1px;
  }
  .rm-ul-col, .rm-ul  {
    font-size: 20px;
  }
  .ul-otzivi {
    font-size: 20px;
  }
  .img-rm, .img-want, .img-500 {
    width: 450px;
  }
  .h2-title-4 {
    font-size: 100px;
  }
  .h2-title-4 span {
    font-size: 120px;
  }
  .h2-title-5 {
    font-size: 80px;
  }
  .h2-title-5 span {
    font-size: 100px;
  }
  .h4-title {
    font-size: 24px;
  }
  .h3-form {
    font-size: 22px;
  }
  .img-send {
    width: 300px;
  }
}
@media (min-width: 1200px) {
  .section-6 .h2-title-sw {
    font-size: 34px;
    letter-spacing: 1px;
  }
  .section-9 .h2-title-sw {
    font-size: 36px;
    letter-spacing: 1px;
    padding-bottom: 0px;
  }
}
@media (max-width: 1400px) {
  .img-send {
    max-width: 300px;
  }  
  .img-rm, .img-want, .img-500 {
    max-width: 450px;
  }
}
@media (max-width: 1300px) {
  .h2-main, .h3-main {
    letter-spacing: normal;
  }
  .robot-setevik  {
    width: 300px;
    bottom: 0;
  }
  .section-3 .rm-ul-col {
    max-width: 350px;
  }
  .robo-had {
    width: 200%;
    top: -200px;
  }
  .section-8 .h2-title-sw {
    padding-top: 60px;
    padding-bottom: 30px; 
  }
}
@media (max-width: 1200px) {
  .rm-ul-col, .rm-ul {
    padding-left: 10px !important;
  }
  .h2-title-sw {
    letter-spacing: 2px;
  }
  .h3-title {
    padding-bottom: 20px;
  }
  .sec-2-row-btm .col-4 {
    position: relative;
  }
  .sec-2-row-btm .col-4 .robot-setevik {
    // width: calc(150px + (350 - 20) * ((100vw - 320px) / (1280 - 320)));
    // position: absolute;
    // bottom: 0px;
    // right: 0px;
  }
  .br-mob-6 {
    display: block;
  }
  .robo-had {
    top: -200px;
  }
  .hands-ball {
    top: -120px;
  }
  .steps-man {
    top: -80px;
    right: -0px;
    width: 750px;
  }
  .section-9 .h2-title-sw {
    margin-bottom: -20px;
  }
  .notebook-hand {
    top: -50px;
  }
  .robot-setevik  {
    width: 280px;
  }
}
@media (max-width: 1100px) {
  .robo-had {
    top: -160px;
  }
}
@media (max-width: 1000px) {
  .robot-setevik  {
    width: 250px;
  }
  .section-3 .rm-ul-col {
        max-width: 300px;
    }
  .rm-ul-col, .rm-ul {
    padding-left: 0px !important;
  }
  .br-mob-62 {
    display: block;
  }
  .hands-ball {
    top: -80px;
    left: -50px;
  }
  .steps-man {
    top: -120px;
  }
  .notebook-hand {
    top: -30px;
  }
}
@media (max-width: 960px) {  
  // .wrapper {
  //   background: linear-gradient(to right, #3D1C5A, #302058);
  // }
  .h1-main {
    padding-left: 150px;
  }
  .h2-main {
    
  }
  .br-main {
    display: block;
  }
  .txt-main {
    // justify-content: left;
    padding-left: 40px;
  }
  .h3-main {
    // margin-left: 30px;
    // font-size: calc(9px + (36 - 12) * ((100vw - 320px) / (960 - 320)));
    margin-bottom: 40px;
  }
  .h4-main {
    margin-left: 0px;
    // font-size: calc(9px + (28 - 12) * ((100vw - 320px) / (960 - 320)));
    margin-bottom: 40px;
  }
  .main-btm-txt .col-md-4 {
    position: relative;
  }
  .bg-main-btm {
    position: absolute;
    width: 240%;
    bottom: -50px;
    right: 20px;
  }
  .section-2 {
    margin-top: -40px;
  }
  .section-3 .h3-title {
    margin-top: 30px;
  }
  .section-3 .rm-ul-col {
    font-size: calc(14px + (20 - 12) * ((100vw - 320px) / (1280 - 320)));
  }
  .rm-ul-col, .rm-ul {
    font-size: 12px;
  }
  .rm-ul-col li, .rm-ul li, .ul-otzivi li {
    padding-left: 35px;
  }
  .check-li {
    width: 25px;
    top: -5px;
  }
  .h2-title-sw {
    letter-spacing: 0px;
    text-shadow: 1px 1px 8px #ccc;
  }
  .sec-2-row-btm .col-4 {
    position: relative;
  }
  .sec-2-row-btm .col-4 .robot-setevik {
    // width: calc(150px + (350 - 20) * ((100vw - 320px) / (1280 - 320)));
    // position: absolute;
    // bottom: 0;
    // right: 0px;
    // width: 85%;
  }  
  .sec-2-br-mob {
    display: block;
  }
  .sec-2-br {
    display: none;
  }
  .robot-setevik  {
    width: 300px;
    bottom: 0px;
  }
  .sec-2-col-div {
    padding-left: 20%;
  }
  .br-mob-5 {
    display: block;
  }
  .txt-sec-5 {
    margin-top: 0;
  }
  .txt-sec-5 .h3-title {
    padding-bottom: 0;
  }
  .steps-man {
    top: -60px;
    right: 0px;
    width: 500px;
  }
  .hands-col {
    left: -180px;
    top: -80px;
  }
  .robo-had {
        width: 250%;
    }
  .txt-btm-8 .h3-title {
    margin-top: 20px;
  }
  .br-mob-9 {
    display: block;
  }
  .section-9 .h2-title-sw {
    margin-bottom: -30px;
  }
  .img-carousel {
    margin-left: -50px;
  }
  .ul-otzivi {
    padding-top: 0;
  }
  .section-9 .h3-title {
    margin-top: 20px;
  }
  .br-92 {
    display: none;
  }
  .br-mob-92 {
    display: block;
  }
}
@media (max-width: 800px) {  
  .bg-main-btm {
    bottom: -30px;
  }
  .rm-ul-col, .rm-ul {
    font-size: 10px;
    letter-spacing: 0.5px;
  }
  .hands-ball {
    top: -50px;
  }
  .hands-col {
    top: -60px;
  }
  .robo-had {
    width: 280%;
        right: -50px;
  }
  .video-block {
    padding: 0 5px;
  }
  .rm-carousel {
    height: 390px !important;
  }    
  .section-9 .h2-title-sw {
    margin-bottom: -40px;
  }
  .section-92 .rm-ul-col {
    font-size: 14px;
  }
  .notebook-hand {
    width: 150%;
    left: -150px;
  }
}
@media (max-width: 760px) {
  .bg-main-btm {
    // width: 200%;
    bottom: 0px;
  }
  .robot-setevik  {
    width: 260px;
    bottom: 20px;
  }
  .section-4 .br-mob {
    display: none;
  }
  .hands-col {
    left: -150px;
  }
  .sec-2-row-btm .col-4 .robot-setevik {
    width: 100%;
    right: -10px;
  }
  .notebook-hand {
    width: 170%;
  }
}
@media (max-width: 700px) {
  .hands-col {
    top: -30px;
    left: -100px;
  }
  .robo-had {
    width: 300%;
    top: -100px;
  }
  .rm-carousel {
    height: 370px !important;
  }
  .notebook-hand {
    width: 180%;
    left: -100px;
  }
}  
@media (max-width: 650px) {
  .rm-carousel {
    height: 350px !important;
  }
}  
@media (max-width: 600px) {  
  .h1-main {
    padding-left: 100px;
    padding-top: 0px;
    padding-bottom: 10px;
  }
  .br-main-mob {
    display: block;
  }
  .br-main {
    display: none;
  }
  .h2-main {
    letter-spacing: 0px;
    text-align: center;
    margin-left: 0;
  }
  .h3-main {
    margin-top: 20px;
  }
  .bg-main-btm {
    // width: 180%;
    // bottom: 50px;
  }
  .h3-title {
    letter-spacing: 1.5px;
  }
  .br-mob {
    display: none;
  }
  .bg-end.end-mob {
    display: none;
  }
  .h3-title-mob-form {
    display: none;
  }
  .main-btm-txt {
    // margin-top: 10px;
  }
  .main-btm-txt .col {
    padding: 0 10px 0 0;
  }
  .sec-2-row-top .col-sm-6, .sec-5-row-top .col-sm-6 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    // max-width: 320px !important;
    margin: 0 auto;
  }
  .sec-2-row-top, .sec-5-row-top {
    padding-top: 0px;
  }
  .sec-2-row-btm .h3-title {
    display: none;
  }
  .robot-setevik  {
    width: 200%;
    bottom: 20px;
  }
  .sec-2-col-div {
        padding-left: 10%;
    }
    .bg-sec-23 {
      display: none;
    }
    .section-3 {
      margin-top: -40px;
    }
  .section-4 .h3-title {
    text-align: center;
    margin-top: 10px;
    padding-left: 0;
    margin-left: 0;
  }
  .section-5 .h2-title-sw {
    text-align: left;
    margin-left: 40px;
  }
  .txt-sec-5 {
    margin-top: 20px;
  }
  .sec-2-row-btm .col-4 .robot-setevik {
    // width: 130%;
    // position: absolute;
    // bottom: 0;
    // right: -70px;
  }
  .rm-ul-col, .rm-ul {
    font-size: 10px;
  }
  .txt-main {
    padding-left: 10px !important;
    padding-right: 0 !important;
  }
  .robo-had {
    width: 400%;
    top: -100px;
  }
  .robo-mozg {
    display: none;
  }
  .robo-mozg-mob {
    display: block;
    position: absolute;
    width: 250px;
    right: 0;
    bottom: -30%;
  }
  .h2-title-4 {
    padding-left: 0;
    letter-spacing: 1.5px;
    margin-top: 10px;
  }
  .section-5 {
    background-image: none;
    padding-top: 40px;
  }
  .h3-title-5 {
    padding-bottom: 20px;
  }
  .h3-title-5b {
    padding-bottom: 10px;
  }
  .h2-title-5 {
    padding-left: 0;
    letter-spacing: 1.5px;
  }
  .col-img-abs.not-mob {
    display: none;
  }
  .steps-man-mob, .hands-ball-mobile {
    display: block;
  }
  .steps-man-mob {
    position: absolute;
    width: 200px;
    right: 0;
    top: -70px;
  }
  .hands-ball-mobile {
    width: 100%;
    position: relative;
    top: -30px;
  }
  .section-62 .h3-title-6 {
    margin-top: -20px !important;
  }
  .robo-had-mobile {
    display: block;
    position: absolute;
    transform: scaleX(-1);
    width: 250px;
    right: -30px;
    bottom: -50px;
    opacity: 0.7;
    z-index: 100;
  }
  .col-img-abs.col-sm-4 {
    height: 100px;
  }
  .hands-col {
    position: relative;
    width: 70%;
    left: 12%;
    top: -90px;
    opacity: 0.7;
  }
  .br-mob-8 {
    display: block;
  }
  .h3-title-6 {
    letter-spacing: 1.5px;
  }
  .rm-carousel {
    height: 320px !important;
  }
  .h3-form-br-mob {
    display: block;
  }
  .h2-title-mob-form {
    display: block !important;
    font-size: 14px !important;
    padding-top: 0 !important;
  }
  .h2-title-sw.h2-title-mob-form .br-mob {
    display: block;
  }
  .col-img-92 {
    display: none;
  }
  .notebook-hand-mob {
    display: block;
    width: 60%;
    margin: 0 auto;
  }
  .section-10 .h2-title-sw {
    // display: none;
  }
  .section-10 .h3-title {
    margin-top: 0;
  }
  .section-9 .h3-title {
    margin-top: 20px;
    text-shadow: 1px 1px 8px #ccc;
  }
  .section-92 .h3-title {
    padding-bottom: 0;
    letter-spacing: 0;
  }
  .bg-end {
    display: none;
  }
  .section-10 .h2-title-sw {
    padding-bottom: 20px;
    padding-top: 0;
  }
}
@media (max-width: 520px) {
  .h3-main {
    // margin-left: 10px;
    margin-bottom: 30px;
    // letter-spacing: 1px;
  }
  .robo-had {
        width: 450%;
        top: -70px;
    }
  .h4-main {
    margin-left: 10px;
    margin-bottom: 30px;
    // letter-spacing: 1px;
  }
  .hands-col {
    top: -70px;
  }
  .rm-carousel {
    height: 300px !important;
  }
  .sec-2-row-btm .col-4 .robot-setevik {
    width: 115%;
    right: -30px;
  }
}
@media (max-width: 480px) {  
  .h1-main {
    padding-left: 80px;
  }
  .h3-title {
    letter-spacing: 1px;
  }
  .bg-main-btm {
    // bottom: 40px;
  }
  .section-2 {
    margin-top: -20px;
  }
  .section-2 .h2-title {
    padding-bottom: 10px;
  }
  .robot-setevik {
        width: 180%;
        bottom: 50px;
    }
  .sec-2-row-top, .sec-5-row-top {
    padding-left: 10px;
    padding-right: 10px;
  }
  .rm-ul-col {
    padding-left: 5px !important;
  }
  .rm-ul-col li {
    padding-left: 30px;
    padding-bottom: 10px;
  }
  .check-li {
    width: 20px;
    top: -5px;
  }
  .sec-2-row-btm .col-4 .robot-setevik {
    width: 110%;
    right: -20px;
    bottom: -10px;
  }
  .sec-2-row-btm {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sec-2-row-btm .col {
    padding-left: 0;
    padding-right: 0;
    padding-top: 20px;
  }
  .sec-2-row-btm .h3-title {
    font-size: 10px;
    letter-spacing: 1px;
    padding-bottom: 3px;
  }
  .sec-2-row-btm .h3-title .h3-span {
    font-size: 12px;
    padding-top: 3px;
  }
  // .robot-setevik {
  //   width: 140%;
  //   top: -15px;
  // }
  .txt-col {
    padding-top: 0;
  }
  .bg-sec-23 {
    bottom: -15%;
  }
  .section-3 .h3-title {
    margin-top: 20px;
    padding-bottom: 10px;
  }
  .h2-title-sw .br-mob-3 {
    display: block;
  }
  .h2-title-sw .br-mob-32 {
    // display: none;
  }
  .rm-ul {
    padding-left: 0 !important;
    letter-spacing: 0px;
  }
  .rm-ul li {
    padding-left: 30px;
    padding-bottom: 10px;
  }
  .rm-ul li .check-li {
    top: -1px;
  }
  .section-4 .h3-title {
    font-size: 13px;
  }
  .robo-mozg-mob {
    width: 200px;
    bottom: -25%;
  }
  .section-5 {
    padding-top: 30px;
  }
  .section-5 .h2-title-sw {
    margin-left: 20px;
  }
  .section-5 .rm-ul-col {
    padding-left: 10px !important;
  }
  .sec-5-row-top .col-sm-6 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .sec-5-row-top {
    margin-top: 0px;
  }
  .txt-sec-5 {
    margin-top: 10px;
  }
  .txt-sec-5 .h3-title {
    padding-bottom: 10px;
  }  
  .section-6 .h2-title-sw {
    font-size: 15px;
    padding-bottom: 15px;
  }
  .col-sec-6-top {
    padding-left: 0;
  }
  .steps-man-mob {
    width: 180px;
    top: -60px;
    right: -20px;
  }
  .col-sec-6-top .rm-ul-col {
    padding-left: 0 !important;
  }
  .col-sec-6-top .col-sm-8, .col-sec-6-btm .col-sm-8 {
    padding: 5px 0 0 8px;
  }
  .hands-col {
    top: -60px;
    left: 15%;
  }
  .robo-had-mobile {
    width: 200px;
    right: -30px;
    bottom: -50px;
  }
  .col-sec-6-btm {
    padding-left: 0;
  }
  .col-sec-6-btm .rm-ul-col {
    padding-left: 0 !important;
  }
  .h3-title-6 {
    letter-spacing: 1px;
    margin-top: 20px;
    padding-bottom: 20px;
  }
  .section-7 .h2-title-sw {
    font-size: 15px;
    letter-spacing: 1px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .section-8 {
    padding-top: 20px;
  }
  .section-8 .h2-title-sw {
    font-size: 14px;
    letter-spacing: 1px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .txt-btm-8 .h3-title {
    margin-top: 20px;
    padding-bottom: 0;
  }
  .rm-carousel .v-carousel__item {
    height: 380px !important;
  }
  .img-carousel {
    margin-left: -40px;
  }
  .section-9 .h2-title-sw {
    margin-bottom: -60px;
  }
  .ul-otzivi {
    padding-left: 10px !important;
  }
  .ul-otzivi li {
    padding-left: 30px;
    padding-bottom: 10px;
    letter-spacing: 0;
  }
  .section-9 .h3-title {
    margin-top: 10px;
    padding-bottom: 5px;
  }
  .section-10 .h2-title-sw {
    font-size: 14px;
    letter-spacing: 0px;
    padding-bottom: 20px;
  }
  .h3-form {
    margin-bottom: 10px;
  }
  .privacy-text.rm-privacy {
    font-size: 8px !important;
  }
  .section-10 .h3-title {
    margin-top: 0px;
    // padding-bottom: 0;
  }
  .rm-footer {
    height: 70px;
  }
  .rm-footer .title {
    font-size: 9px !important;
  }
}
@media (max-width: 450px) {
  .rm-carousel {
    height: 290px !important;
  }
}
@media (max-width: 420px) {
  .section-3 {
        margin-top: -60px;
    }
  .robot-setevik {
        width: 140%;
        bottom: 70px;
    }
    .sec-2-col-div {
        padding-left: 0;
    }
  .steps-man-mob {
    width: 160px;
  }
  .section-62 .rm-ul-col {
    font-size: 13px;
  }
  .hands-col {
    top: -50px;
  }
  .robo-had-mobile {
    width: 180px;
  }
  .rm-carousel {
    height: 270px !important;
  }
}
@media (max-width: 400px) {
  .h2-title-mob-form {
    font-size: 13px !important;
  }
  .steps-man-mob {
    width: 140px;
    opacity: 0.7;
    top: -50px;
  }
  .br-form-cntct-mob {
    display: block;
  }
}
@media (max-width: 360px) {
  .sec-2-row-btm .col-4 .robot-setevik {
    width: 120%;
    right: 0px;
    top: 0;
  }
  // .robot-setevik {
  //       width: 140%;
  //       bottom: 90px;
  //   }
    .robo-had {
        width: 500%;
        top: -50px;
    }
    .section-2 .h2-title {
        letter-spacing: 2px;
        padding-top: 10px;
    }
    .section-2 .h3-title {
      font-size: 9px;
    }
  .h2-title-mob-form {
    font-size: 12px !important;
  }
  .rm-ul-col, .rm-ul {
    // font-size: 13px;
  }
  .hands-col {
    width: 80%;
    top: -50px;
  }
  .robo-had-mobile {
    width: 150px;
  }
  .robo-mozg-mob {
    width: 180px;
    bottom: -20%;
  }
  .section-5 {
    padding-top: 20px;
  }
  .section-9 .h2-title-sw {
    margin-bottom: -75px;
  }
  .rm-carousel {
    height: 250px !important;
  }
  .img-carousel {
    margin-left: -30px;
  }
  .ul-otzivi {
    padding-top: 10px;
  }
  .section-92 .h3-title {
    font-size: 13px;
  }
}
</style>